import React from "react"
import Page from "../components/page"

const NotFoundPage = () => (
  <Page title="404">
    <section style={{ textAlign: "center" }}>
      <h1>Questa non è la pagina che stavi cercando...</h1>
      <img
        style={{  width:  "100%", margin: "36px 0"  }}
        src="https://media.giphy.com/media/l2JJKs3I69qfaQleE/giphy.gif"
        alt="GIF da Star Wars con Obi-Wan Kenobi: 'Questi non sono i droidi che state cercando'"
      />
      <p>
        La pagina che stavi cercando non esiste. Se pensi che questo sia un
        errore, scrivici a{" "}
        <a href="mailto:ciao@investitoriribelli.it">
          <strong>ciao@investitoriribelli.it</strong>
        </a>
      </p>
    </section>
  </Page>
)

export default NotFoundPage
